import React from "react"
import {graphql} from "gatsby"
import styled from "styled-components";

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import TextPageHeader from "../components/TextPageHeader";
import ResponsiveEmbed from 'react-responsive-embed';

import ContainerSmall from "../styles/ContainerSmall";

const SupportText = styled.div`

 max-width: 873px;
 margin-right: auto;
 margin-left: auto;
 margin-top: 40px;
 margin-bottom: 40px;
 padding-right: 15px;
 padding-left: 15px;

  li {
    margin-bottom: .3rem;
  }

  a {
    color: ${props => props.theme.red};
  }

`



class SupportTemplate extends React.Component {
  render() {


    const post = this.props.data.markdownRemark;

    let vimeoUrl = `https://player.vimeo.com/video/${post.frontmatter.vimeoid}`
    let iframe = <ResponsiveEmbed src={vimeoUrl} allowfullscreen/>


    return (
      <Layout location={this.props.location} grayFooter={true}>

        <TextPageHeader
          title={post.frontmatter.title}
        />
        <ContainerSmall>
          {iframe}
        </ContainerSmall>
        <SupportText>
          {post.frontmatter.doc !== null &&
          <div>
            <h2>Download</h2>
            <p><a href={'/support/' + post.frontmatter.doc}>PDF — {post.frontmatter.title}</a></p>
          </div>
          }

          <section dangerouslySetInnerHTML={{__html: post.html}}/>
        </SupportText>


      </Layout>
    )
  }
}

export default SupportTemplate;

export const Head = (props) => {


  return (
    <Seo
      title={props.data.markdownRemark.frontmatter.title}
      description={props.data.markdownRemark.frontmatter.teaser}
      canonical={props.location.pathname}
    />
  )
}

export const pageQuery = graphql`
  query SupportBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        vimeoid
        path
        doc
        title
      }
    }
  }
`

